<template>
    <ValidationObserver ref="signIn">
        <div class="container scope">
            <section class="py-5">
                <div class="mb-4" data-aos="fade-in" data-aos-duration="2400">
                    <h2 class="text-white mx-auto text-center bold">
                        {{ t("accounts.userAccounts") }}
                    </h2>
                    <p style="max-width: 800px"
                       class="mt-2 mx-auto text-center text-white">
                        {{ t("website.signinInfo") }}
                    </p>
                </div>
                <b-col cols="12">
                    <b-row>
                        <b-col cols="12" md="6" class="text-center">
                            <div>
                                <div
                                    data-aos="fade-right"
                                    data-aos-duration="2500"
                                >
                                    <EKInputText
                                        class="d-block w-75 mx-auto sign-in"
                                        v-model="signInDto.username"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: t(
                                                    'accounts.validation.mobileNumber'
                                                )
                                            },
                                            {
                                                type: 'mobile',
                                                message: t(
                                                    'accounts.validation.validMobile'
                                                )
                                            }
                                        ]"
                                        :placeholder="
                                            t('accounts.mobileNumber')
                                        "
                                        name="mobileNumber"
                                    />
                                </div>
                                <div
                                    data-aos="fade-right"
                                    data-aos-duration="2500"
                                >
                                    <EKInputText
                                        class="d-block w-75 mx-auto sign-in"
                                        style="border-radius: 2px"
                                        v-model="signInDto.password"
                                        type="password"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: t(
                                                    'accounts.validation.password'
                                                )
                                            }
                                        ]"
                                        :placeholder="t('accounts.password')"
                                        name="password"
                                    />
                                </div>
                            </div>
                            <b-button
                                variant="flat-secondary"
                                class="custom-btn"
                                data-aos="fade-left"
                                @click="signInAccount"
                            >
                                <img
                                    src="/media/images/site-images/btn-bg.svg"
                                />
                                <span>
                                    {{ t("accounts.signIn") }}
                                </span>
                            </b-button>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            class="d-flex align-self-center"
                        >
                            <div
                                class="button-border rounded mx-auto mb-5"
                                data-aos="fade-up"
                                data-aos-duration="3000"
                            >
                                <b-button
                                    variant="flat-secondary"
                                    class="text-nowrap d-flex align-items-center signup-button px-5 py-2 rounded"
                                    @click="navigateToSignUp"
                                >
                                    {{ t("accounts.newCustomer") }}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </section>
        </div>
    </ValidationObserver>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import { mapState, mapMutations, mapActions } from "vuex";
import { BToast } from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { Customer } from "@/router";
import { getCookie } from "@Ekcore/util/global";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
    components: {
        ValidationObserver,
        EKInputText
    },
    setup() {
        const { t } = useI18nUtils();
        return { t };
    },
    beforeDestroy() {
        window.removeEventListener("keypress", this.enterSubmit);
    },
    created() {
        this.Rest_SignIn_Dto();
        this.$store.commit("Log_Out");
        window.addEventListener("keypress", this.enterSubmit);
    },
    computed: {
        ...mapState({
            signInDto: state => state.websiteAccounts.signInDto
        })
    },
    methods: {
        ...mapActions(["getItemsCount"]),
        ...mapMutations(["Rest_SignIn_Dto"]),
        enterSubmit({ key, code }) {
            if (key == "Enter" || code == "Enter") {
                this.signInAccount();
            }
        },
        navigateToSignUp() {
            this.$router.push(`/auth-signup`);
        },
        signInAccount() {
            const bToast = new BToast();
            this.$refs.signIn.validate().then(success => {
                if (success) {
                    useJwt
                        .signin({
                            username: this.signInDto.username,
                            password: this.signInDto.password,
                            rememberMe: this.signInDto.rememberMe,
                            hashedCart: getCookie("awijaCart")
                        })
                        .then(response => {
                            const customerData = response.data;
                            useJwt.setWebsiteToken(response.data.token);
                            localStorage.setItem(
                                "customerData",
                                JSON.stringify(customerData)
                            );
                            if (customerData.role === Customer) {
                                this.$router
                                    .replace("/")
                                    .then(() => {
                                        this.$store.commit("SET_TOKEN");
                                        this.getItemsCount();
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            } else {
                                bToast.$bvToast.toast(
                                    `رقم الموبايل أو كلمة المرور خطأ, يرجى التأكد!`,
                                    {
                                        title: "خطأ تسجيل الدخول",
                                        variant: "danger",
                                        toaster: "b-toaster-bottom-left",
                                        solid: true,
                                        autoHideDelay: 2000,
                                        appendToast: true
                                    }
                                );
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            bToast.$bvToast.toast(
                                `اسم المستخدم او كلمة المرور خطأ!`,
                                {
                                    title: "خطأ تسجيل الدخول",
                                    variant: "danger",
                                    toaster: "b-toaster-bottom-left",
                                    solid: true,
                                    autoHideDelay: 2000,
                                    appendToast: true
                                }
                            );
                        });

                    this.$refs.signIn.reset();
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.button-border {
    padding: 2px;
    background: linear-gradient(
        45deg,
        #afadad,
        #ffffff,
        #bbbbbb,
        #ffffff,
        #bbbbbb,
        #ffffff
    );
}
.scope ::v-deep .signup-button {
    background: #47494a !important;
    border: none;
}

.custom-btn {
    position: relative;
    background: transparent !important;
    padding: 1rem;
    margin-top: 2rem;
    img {
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
    }
    span {
        position: relative;
        z-index: 1;
        color: #fff;
    }
}
.scope ::v-deep .sign-in .form-control {
    padding: 2.5rem;
    background-color: inherit;
    border: solid 3px white;
    color: white !important;
    box-shadow: inset -2px 2px 4px rgba(255, 255, 255, 0.1),
        inset 2px -2px 4px rgba(0, 0, 0, 0.1);
}
.signup-button {
    color: var(--custom-blue-sky);
}
</style>
