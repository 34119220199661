var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"signIn"},[_c('div',{staticClass:"container scope"},[_c('section',{staticClass:"py-5"},[_c('div',{staticClass:"mb-4",attrs:{"data-aos":"fade-in","data-aos-duration":"2400"}},[_c('h2',{staticClass:"text-white mx-auto text-center bold"},[_vm._v(" "+_vm._s(_vm.t("accounts.userAccounts"))+" ")]),_c('p',{staticClass:"mt-2 mx-auto text-center text-white",staticStyle:{"max-width":"800px"}},[_vm._v(" "+_vm._s(_vm.t("website.signinInfo"))+" ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('div',[_c('div',{attrs:{"data-aos":"fade-right","data-aos-duration":"2500"}},[_c('EKInputText',{staticClass:"d-block w-75 mx-auto sign-in",attrs:{"rules":[
                                        {
                                            type: 'required',
                                            message: _vm.t(
                                                'accounts.validation.mobileNumber'
                                            )
                                        },
                                        {
                                            type: 'mobile',
                                            message: _vm.t(
                                                'accounts.validation.validMobile'
                                            )
                                        }
                                    ],"placeholder":_vm.t('accounts.mobileNumber'),"name":"mobileNumber"},model:{value:(_vm.signInDto.username),callback:function ($$v) {_vm.$set(_vm.signInDto, "username", $$v)},expression:"signInDto.username"}})],1),_c('div',{attrs:{"data-aos":"fade-right","data-aos-duration":"2500"}},[_c('EKInputText',{staticClass:"d-block w-75 mx-auto sign-in",staticStyle:{"border-radius":"2px"},attrs:{"type":"password","rules":[
                                        {
                                            type: 'required',
                                            message: _vm.t(
                                                'accounts.validation.password'
                                            )
                                        }
                                    ],"placeholder":_vm.t('accounts.password'),"name":"password"},model:{value:(_vm.signInDto.password),callback:function ($$v) {_vm.$set(_vm.signInDto, "password", $$v)},expression:"signInDto.password"}})],1)]),_c('b-button',{staticClass:"custom-btn",attrs:{"variant":"flat-secondary","data-aos":"fade-left"},on:{"click":_vm.signInAccount}},[_c('img',{attrs:{"src":"/media/images/site-images/btn-bg.svg"}}),_c('span',[_vm._v(" "+_vm._s(_vm.t("accounts.signIn"))+" ")])])],1),_c('b-col',{staticClass:"d-flex align-self-center",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"button-border rounded mx-auto mb-5",attrs:{"data-aos":"fade-up","data-aos-duration":"3000"}},[_c('b-button',{staticClass:"text-nowrap d-flex align-items-center signup-button px-5 py-2 rounded",attrs:{"variant":"flat-secondary"},on:{"click":_vm.navigateToSignUp}},[_vm._v(" "+_vm._s(_vm.t("accounts.newCustomer"))+" ")])],1)])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }